import Chatbox from "../Components/Chatbox";
import Footer from "../Components/footer";
import Form from "../Components/Form";
import Header from "../Components/Header";
import Scroller from "../Components/Scroller";


const CustomProject = () => {

const customimg1 = "https://www.datametricks.com/website-image/customimg1.svg";
const customimg2 = "https://www.datametricks.com/website-image/customimg2.svg";
const customimg3 = "https://www.datametricks.com/website-image/customimg3.svg";
const customimg4 = "https://www.datametricks.com/website-image/customimg4.svg";
const customimg5 = "https://www.datametricks.com/website-image/customimg5.svg";
const customimg6 = "https://www.datametricks.com/website-image/customimg6.svg";
const customimg7 = "https://www.datametricks.com/website-image/customimg7.svg";
const customimg8 = "https://www.datametricks.com/website-image/customimg8.svg";
const customimg9 = "https://www.datametricks.com/website-image/customimg9.svg";
const customicon1 = "https://www.datametricks.com/website-image/customicon3.svg";
const customicon2 = "https://www.datametricks.com/website-image/customicon1.svg";
const customicon3 = "https://www.datametricks.com/website-image/customicon2.svg";

    return (

        <div >
            
            <div style={{backgroundColor:'rgba(235, 234, 255, 1)'}}>

           
            <div>
                <Header/>
            </div>

            <section style={{marginTop:'5%'}}>
                <div className="container pt-5" >
                    <div className="row">
                        <div className="col-lg-4 ">
                            <div className="rotateview" style={{rotate:'-15deg',marginTop:'40px'}}>
                                <Scroller/>

                            </div>
                        </div>
                        <div className="col-lg-4">
                            
                        </div>
                        <div className="col-lg-4 text-end">
                     <a href="/SmartcareProject"> <img src={customimg1} alt="" className="img-fluid pt-20" /></a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center textcenter">
                            <p className="fs-50 fw-800 plus font-23 mb-0 pt-20" style={{color:'rgba(26, 26, 26, 1)'}}>Beautiful Websites, Designed</p>
                            <p className="fs-50 fw-800 plus font-23 mb-0" style={{color:'rgba(26, 26, 26, 1)'}}>Scratch</p>
                            <p className="fs-17 fw-400 plus mb-0 font-18 " style={{color:'rgba(34, 34, 34, 0.6)'}}>Stand out the crowd with a modern WordPress website that’s 100% unique to you.</p>
                            <p className="fs-17 fw-400 plus font-18" style={{color:'rgba(34, 34, 34, 0.6)'}}>that’s 100% unique to you.</p>

                        </div>
                    </div>

                    <div className="row pb-4">
                        <div className="col-lg-4 textstart1 text-lg-start  ">
                         <a href="/LookplexProject">  <img src={customimg2} alt="" className="img-fluid pt-10 " /></a>
                        </div>
                        <div className="col-lg-4 text-center">
                            <a href="Contactus"> <button type="button" className="btn   btn-sm pb-0  pt-2 mt-4" style={{backgroundColor:'rgba(0, 110, 205, 1)',boxShadow:'none',border:'none',outline:'none',borderRadius:'42px',paddingLeft:'50px',paddingRight:'50px'}}><p className="fs-19 fw-600 plus text-white" >Contact us</p></button></a>

                        </div>

                        <div className="col-lg-4 text-lg-end pt-20 textstart1">
                        <a href="/HealthomaxWebProject"><img src={customimg3} alt="" className="img-fluid" /></a>
                        </div>
                    </div>

                </div>

            </section>

            </div>

            <section >
                <div className="p-lg-5 padding1" style={{backgroundColor:'rgba(255, 242, 242, 1)'}}>
                    <div className="text-center">
                        <p className="fs-22 fw-500 plus font-23">Custom Website Development?</p>
                        <p className="fs-35 fw-700 plus font-23">Using just Pure Love & Passion</p>
                        <p className="fs-17 plus font-18" style={{color:'rgba(34, 34, 34, 0.6)'}}>An interactive website integrates software into the web page to engage visitors with a more relevant experience. Or you can say a website in which visitors can post their replies and comments to interact with someone behind the website to get solutions for their query in a more entertaining and efficacious manner. Instead of entering in the website and just clicking into a page full of words and boring pictures, visitors click into an exciting web design that immediately jumps off the screen to greet and acknowledge them.</p>
                    </div>
                </div>

            </section>
            <section >
                <div className="mt-lg-4" >
                    <p className="fs-36 fw-700 plus text-center mb-0 font-23">Details About your Development</p>
                    <p className="fs-36 fw-700 plus text-center font-23"> Workflow</p>

                </div>
                <div className="container mt-lg-5 mt0">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 text-center">
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(28, 201, 176, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>
                        <div className="col-lg-3 text-center">
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(57, 109, 233, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>
                        <div className="col-lg-3 text-center">
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(120, 78, 212, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>

                    </div>
                    <div className="row text-center mt-2">
                        <div className="col-lg-12">
                            <img src={customimg4} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3 ">
                        <div className="col-lg-3 text-center  " >
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(31, 185, 207, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>
                        <div className="col-lg-3 text-center ps-5" >
                            <p className="fs-18 fw-700 plus mb-0 inter"style={{color:'rgba(206, 72, 234, 1)'}}>Tittle Here</p>
                            <p className="fw-400 fs-12 plus inter" style={{color:'rgba(150, 150, 150, 1)'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusan-
                            tium doloremque</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-lg-5 mt-10">
               <div>
                    <p className="fs-36 fw-700 plus text-center mb-0 font-23">Information on Tailored Services </p>
                    <p className="fs-36 fw-700 plus text-center font-23"> and Unique Offerings</p>

               </div>
               <div className="container mt-5">
                <div className="row justify-content-center textcenter">
                    <div className="col-lg-3 ">
                        <div className="p-4 h-100" style={{border:'0.73px solid rgba(197, 197, 197, 1)', borderRadius:'13px'}}>
                        <img src={customicon1} alt="" />
                        <p className="fs-20 fw-700 plus pt-4">High Performance – Standard</p>
                        <p className="fs-14 plus " style={{color:'rgba(0, 0, 0, 0.5)'}}>We only design polished WordPress websites that lend your business credibility and trust with visitors. All SEO-ready and mobile-friendly, with a drag-n-drop builder so you can make changes easily.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 mt-10">
                        <div className="p-4 h-100" style={{border:'0.73px solid rgba(197, 197, 197, 1)', borderRadius:'13px'}}>
                        <img src={customicon3} alt="" />
                        <p className="fs-20 fw-700 plus pt-4">Drag-n-Drop for Easy Changes</p>
                        <p className="fs-14 plus " style={{color:'rgba(0, 0, 0, 0.5)'}}>We build your website with an intuitive visual editor, so managing your website content is easy. Make changes to your existing text and images, and even build new pages without any coding required.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 mt-10">
                        <div className="p-4 h-100" style={{border:'0.73px solid rgba(197, 197, 197, 1)', borderRadius:'13px'}}>
                        <img src={customicon2} alt="" />
                        <p className="fs-20 fw-700 plus pt-4">Future-Proof with WordPress®</p>
                        <p className="fs-14 plus " style={{color:'rgba(0, 0, 0, 0.5)'}}>Chosen by over 80% of all websites in the world, WordPress is the standard in web software. It’s continuously improved and made more secure, it’s SEO-ready = day one, and it’s crazy extensible thanks to practically infinite 3rd-party plugins you can add as needed.</p>
                        </div>
                    </div>
                </div>
               </div>
            </section>

            <section className="mt-lg-5 mt-10 ">
               <div>
                    <p className="fs-36 fw-700 plus text-center mb-0 font-23">Details About The Tools and  </p>
                    <p className="fs-36 fw-700 plus text-center font-23"> Technologies Utilized</p>

               </div>
               <div className="container mt-lg-5 mt-30">
                    <img src={customimg5} alt="" className="img-fluid" />
               </div>
              
            </section>

            <section className="mt-lg-5 mt-30">
                <div>
                    <img src={customimg6} alt="" className="img-fluid" />
                </div>

            </section>

            <section className="mt-lg-5 mt-30">
               <div>
                    <p className="fs-36 fw-700 plus text-center mb-0 font-23">Examples of custom website development  </p>
                    <p className="fs-36 fw-700 plus text-center font-23"> previous projects </p>

               </div>
               <div className="container mt-lg-5 mt-30">
                    <div className="row justify-content-center textcenter">
                        <div className="col-lg-3">
                             <img src={customimg7} alt="" className="img-fluid " />
                        </div>
                        <div className="col-lg-3">
                             <img src={customimg8} alt="" className="img-fluid mt-10" />
                        </div>
                        <div className="col-lg-3">
                             <img src={customimg9} alt="" className="img-fluid mt-10" />
                        </div>
                    </div>
               </div>
              
            </section>

        
       
<section >
        <div className='newhome-bg1 resnewhome-bg1'>  
  <div className='container p-30'>
    <div className='row justify-content-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <h2 className='fs-50 fw-700 plus text-white font-25 mt-35' style={{marginTop:'200px'}}>Transform Your Digital Presence</h2>
          <p className='fs-18 fw-400 plus font-17' style={{color:'rgba(192, 199, 206, 1)'}}>Ready to transform your digital presence? Fill out the form below to get a free consultation or a  quote for our top-notch website development and digital marketing services. Let's take your  business to new heights together!</p>
      </div>
      <div className='col-lg-4 col-md-6 col-12'>
      <Form/>
        </div>
    </div>
  </div>
  </div>
  
</section>


<section>
  <div>
    <Chatbox/>
  </div>
</section>
            <div className="">
                <Footer/>
            </div>


        </div>

    );
};
export default CustomProject;