
import "./App.css";
import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
   
} from "react-router-dom";
import Homepage1 from "./Pages/Homepage1";
import Aboutus from './Pages/Aboutus';
import Contactus from './Pages/Contactus';
import Wevdev from './Pages/Webdev';
import Digitalmarketing from "./Pages/Digitalmarketing";
import Performancemarketing from './Pages/Performancemarketing';
import Mobiledev from "./Pages/Mobiledev";
import Socialmedia from "./Pages/Socialmedia";
import Privacypolicy from "./Pages/Privacypolicy";
import Termscondition from "./Pages/Termscondition";
import Graphicdesigning from "./Pages/Graphicdesigning";
import Seo from "./Pages/Seo";
import Teams from "./Pages/Teams";
import ProjectScreen from "./Pages/ProjectScreen";
import MonovougeProject from "./Pages/MonovougeProject";
import LookplexProject from "./Pages/LookplexProject";
import CuddlycolaProject from "./Pages/CuddlycolaProject";
import CodetechProject from "./Pages/CodetechProject";
import CustomProject from "./Pages/CustomProject";
import Successemailpage from "./Pages/Successemailpage";
import HealthomaxMobileProject from "./Pages/HealthomaxMobileProject";
import HealthomaxWebProject from "./Pages/HealthomaxWebProject";
import NarayanaHomeProject from "./Pages/NarayanaHomeProject";
import SmartcarePatientProject from "./Pages/SmartcarePatientProject";
import SmartcareDoctorProject from "./Pages/SmartcareDoctorProject";
import SmartcareProject from "./Pages/SmartcareProject";
import SmartcareClinicManagementProject from "./Pages/SmartcareClinicManagementProject";
import GardeningProject from "./Pages/GardeningProject";
import EcommerceWebsiteProject from "./Pages/EcommerceWebsiteProject";
import ContentManagementProject from "./Pages/ContentManagementProject";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="App">
                   
                    <Routes>
                        <Route
                            path="/"
                            element={<Homepage1 />}
                        ></Route>
                        <Route
                            path="/Aboutus"
                            element={<Aboutus />}
                        ></Route>
                        <Route
                            path="/Contactus"
                            element={<Contactus />}
                        ></Route>
                         <Route
                            path="/Webdev"
                            element={<Wevdev />}
                        ></Route>
                         <Route
                            path="/Performancemarketing"
                            element={<Performancemarketing/>}
                        ></Route>
                         <Route
                            path="/Graphicdesigning"
                            element={<Graphicdesigning/>}
                        ></Route>
                        <Route
                            path="/Digitalmarketing"
                            element={<Digitalmarketing/>}
                        ></Route>
                        <Route
                            path="/Mobiledev"
                            element={<Mobiledev/>}
                        ></Route>
                        <Route
                            path="/Socialmedia"
                            element={<Socialmedia/>}
                        ></Route>
                        <Route
                            path="/Seo"
                            element={<Seo/>}
                        ></Route>
                         <Route
                            path="/Privacypolicy"
                            element={<Privacypolicy/>}
                        ></Route>
                         <Route
                            path="/Termscondition"
                            element={<Termscondition/>}
                        ></Route>
                        <Route
                            path="/Teams"
                            element={<Teams/>}
                        ></Route>
                        <Route
                            path="/Homepage1"
                            element={<Homepage1/>}
                        ></Route>
                         <Route
                            path="/ProjectScreen"
                            element={<ProjectScreen/>}
                        ></Route>
                         <Route
                            path="/MonovougeProject"
                            element={<MonovougeProject/>}
                        ></Route>
                         <Route
                            path="/LookplexProject"
                            element={<LookplexProject/>}
                        ></Route>
                         <Route
                            path="/CuddlycolaProject"
                            element={<CuddlycolaProject/>}
                        ></Route>
                         <Route
                            path="/CodetechProject"
                            element={<CodetechProject/>}
                        ></Route>
                         <Route
                            path="/CustomProject"
                            element={<CustomProject/>}
                        ></Route>
                         <Route
                            path="/Successemailpage"
                            element={<Successemailpage/>}
                        ></Route>
                         <Route
                            path="/HealthomaxMobileProject"
                            element={<HealthomaxMobileProject/>}
                        ></Route>
                         <Route
                            path="/HealthomaxWebProject"
                            element={<HealthomaxWebProject/>}
                        ></Route>
                         <Route
                            path="/NarayanaHomeProject"
                            element={<NarayanaHomeProject/>}
                        ></Route>
                         <Route
                            path="/SmartcarePatientProject"
                            element={<SmartcarePatientProject/>}
                        ></Route>
                         <Route
                            path="/SmartcareDoctorProject"
                            element={<SmartcareDoctorProject/>}
                        ></Route>
                         <Route
                            path="/SmartcareProject"
                            element={<SmartcareProject/>}
                        ></Route>
                         <Route
                            path="/SmartcareClinicManagementProject"
                            element={<SmartcareClinicManagementProject/>}
                        ></Route>
                        <Route
                            path="/GardeningProject"
                            element={<GardeningProject/>}
                        ></Route>
                          <Route
                            path="/EcommerceWebsiteProject"
                            element={<EcommerceWebsiteProject/>}
                        ></Route>
                         <Route
                            path="/ContentManagementProject"
                            element={<ContentManagementProject/>}
                        ></Route>
                        
                        
                        
                      
     
                        
                    </Routes>
                </div>
            </Router>
        );
    }
}

export default App;

