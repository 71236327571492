const Footer = ()=>{
    const footertwitter = "https://www.datametricks.com/website-image/footertwitter.svg";

const footerlogo = "https://www.datametricks.com/website-image/footerlogo.svg";
const footerrightarrow = "https://www.datametricks.com/website-image/footerrightarrow.svg";
const footerline = "https://www.datametricks.com/website-image/footerline.svg";
const footerlinkedin = "https://www.datametricks.com/website-image/footerlinkedin.svg";
const footerinsta = "https://www.datametricks.com/website-image/footerinsta.svg";
const footerfb = "https://www.datametricks.com/website-image/footerfb.svg";


    return(

            <div className="main-container">

                <section>
                    <div className="footerbgimg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="mt-30" style={{marginTop:'70px'}}>
                                    <img src={footerlogo} alt="" className="img-fluid footerlogo " />
                                    <p className="fs-18 plus font-14 pt-15" style={{color:'rgba(255, 255, 255, 0.7)',paddingTop:'20px'}}>We specialize in transforming digital visions into reality. We deliver comprehensive website development and digital marketing solutions that drive measurable results. Partner with us to elevate your online presence and achieve your business goals. </p>
                                    </div>
                                </div>
                                <div className="col-lg-8 ps-0 ">
                                    <div className="row ml-0 mt0" style={{marginTop:'40px',marginLeft:'80px'}}>
                                        <div className="col-md-5">
                                              <div style={{backgroundColor:'rgba(0, 0, 0, 0.32)',borderRadius:'12px',backdropFilter:'blur(10px)', paddingLeft:'50px',paddingTop:'25px',paddingBottom:'25px'}}>
                                         <a href="/" style={{textDecoration:'none'}}>   <p className="fw-600 fs-16 plus" style={{color:'rgba(255, 255, 255, 1)'}}>Datametricks</p></a>
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="https://blog.datametricks.com/" style={{textDecoration:'none'}}>    <p className="fs-14 plus " style={{color:'rgba(255, 255, 255, 0.8)'}}>Blogs</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>

                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                  <a href="https://blog.datametricks.com/career/" style={{textDecoration:'none'}}>  <p className="fs-14 plus " style={{color:'rgba(255, 255, 255, 0.8)'}}>Careers</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>

                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Aboutus" style={{textDecoration:'none'}}>  <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>About Us</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Teams" style={{textDecoration:'none'}}>   <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Our Team</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> 
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="ProjectScreen" style={{textDecoration:'none'}}>   <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Our Projects</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> 
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Privacypolicy" style={{textDecoration:'none'}}>  <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Privacy & Policy</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> 
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Termscondition" style={{textDecoration:'none'}}>  <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Terms & Condition</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>
                                                </div>
                                        </div>
                                       
                                        <div className="col-md-7">

                                             <div className="mt-10" style={{backgroundColor:'rgba(0, 0, 0, 0.32)',borderRadius:'12px',backdropFilter:'blur(10px)', paddingLeft:'50px',paddingTop:'25px',paddingBottom:'25px'}}>
                                            <p className="fw-600 fs-16 plus" style={{color:'rgba(255, 255, 255, 1)'}}>Our Services</p>
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Digitalmarketing" style={{textDecoration:'none'}}>   <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Digital Marketing</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>

                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Graphicdesigning" style={{textDecoration:'none'}}>   <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Graphic Designing</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>

                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Webdev" style={{textDecoration:'none'}}>   <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Web Development</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Socialmedia" style={{textDecoration:'none'}}>   <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Social Media Marketing</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> 
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Performancemarketing" style={{textDecoration:'none'}}>  <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Performance Marketing</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> 
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Mobiledev" style={{textDecoration:'none'}}>   <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>Mobile App Development</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div> 
                                            <div className="d-flex" style={{gap:'8.43px'}}>
                                                <div>
                                                <a href="Seo" style={{textDecoration:'none'}}>   <p className="fs-14 plus" style={{color:'rgba(255, 255, 255, 0.8)'}}>SEO  (Search Engine Optimization)</p></a>
                                                </div>
                                                <div>
                                                    <img src={footerrightarrow} alt="" />
                                                </div>

                                            </div>
                                             </div>
                                            
                                        </div>
                                    </div>

                                    <div className="mt-4 ml-14" style={{backgroundColor:'rgba(0, 0, 0, 0.32)',borderRadius:'12px',backdropFilter:'blur(10px)', paddingLeft:'40px',paddingTop:'25px',paddingBottom:'20px',marginLeft:'90px'}}>
                                        <div className="d-flex gap-4 flex-wrap">
                                            <div>
                                                <p className="fs-18 fw-600 plus" style={{color:'rgba(255, 255, 255, 1)'}}>Follow us</p>
                                            </div>
                                            <div>
                                                <img src={footerline} alt="" />
                                            </div>

                                            <div className="d-flex gap-3">
                                            <div>
                                            <a href="https://in.linkedin.com/company/datametricks-consulting-pvt-ltd" style={{textDecoration:'none'}}> <img src={footerlinkedin} alt="" /></a>
                                                </div>
                                                <div>
                                                <a href="https://in.linkedin.com/company/datametricks-consulting-pvt-ltd" style={{textDecoration:'none'}}> <p className="fs-16 plus" style={{color:'rgba(179, 179, 179, 1)'}}>LinkedIn</p></a>
                                                </div>
                                            </div>

                                            <div className="d-flex gap-3">
                                                <div>
                                                <a href="https://www.instagram.com/datametricks_pvt_ltd/" style={{textDecoration:'none'}}>   <img src={footerinsta} alt="" /></a>
                                                </div>
                                                <div>
                                                <a href="https://www.instagram.com/datametricks_pvt_ltd/" style={{textDecoration:'none'}}> <p className="fs-16 plus" style={{color:'rgba(179, 179, 179, 1)'}}>Instagram</p></a>
                                                </div>
                                            </div>

                                            <div className="d-flex gap-3">
                                                <div>
                                                <a href="https://www.facebook.com/people/DataMetricks-Consulting-Pvt-Ltd/61554350043470/" style={{textDecoration:'none'}}>  <img src={footerfb} alt="" /></a>
                                                </div>
                                                <div>
                                                <a href="https://www.facebook.com/people/DataMetricks-Consulting-Pvt-Ltd/61554350043470/" style={{textDecoration:'none'}}>  <p className="fs-16 plus" style={{color:'rgba(179, 179, 179, 1)'}}>Facebook</p></a>
                                                </div>
                                            </div>

                                            <div className="d-flex gap-3">
                                                <div>
                                                <a href="https://x.com/datametricks?t=tTJi9XotG-3d1NMQpxXhTQ&s=09" style={{textDecoration:'none'}}>  <img src={footertwitter} alt="" /></a>
                                                </div>
                                                <div>
                                                <a href="https://x.com/datametricks?t=tTJi9XotG-3d1NMQpxXhTQ&s=09" style={{textDecoration:'none'}}>  <p className="fs-16 plus" style={{color:'rgba(179, 179, 179, 1)'}}>Twitter</p></a>
                                                </div>
                                            </div>
                                         


                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="row mt-4 ">
                                <div className="col-lg-6 ">
                                    <p className="fs-16 plus textcenter" style={{color:'rgba(179, 179, 179, 1)'}}>© Datametricks Consulting Pvt. Ltd. 2024</p>
                                    
                                </div>
                                <div className="col-lg-6  ">
                                    <div className="d-flex  jcc gap-5" style={{justifyContent:'end'}}>
                                        <div>
                                        <a href="Termscondition" style={{textDecoration:'none'}}>  <p className="fs-16 " style={{color:'rgba(179, 179, 179, 1)'}}>Terms & Condition</p></a>
                                        </div>
                                        <div>
                                        <a href="Privacypolicy" style={{textDecoration:'none'}}>  <p className="fs-16 " style={{color:'rgba(179, 179, 179, 1)'}}>Privacy Policy</p></a>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </section >

    <section className="d-block d-sm-block d-md-none d-lg-none">
    <div class="footer-buttons">
        <a href="tel:+918210877534" class="button call-button" style={{textDecoration:'none',}}>Call Us</a>
        <a href="#" class="button whatsapp-button" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}>WhatsApp</a>
    </div>

    </section>
               
            </div>

    );
}
export default Footer;



