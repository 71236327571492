
const MobdevCard = ()=>{

const mobdevimg1 = "https://www.datametricks.com/website-image/Mob-devimg1.png";
const mobdevimg2 = "https://www.datametricks.com/website-image/Mob-devimg2.png";
const mobdevimg3 = "https://www.datametricks.com/website-image/Mob-devimg3.png";
const mobdevimg4 = "https://www.datametricks.com/website-image/Mob-devimg4.png";
const mobdevimg5 = "https://www.datametricks.com/website-image/Mob-devimg5.png";
const mobdevimg6 = "https://www.datametricks.com/website-image/Mob-devimg6.png";
const mobdevimg7 = "https://www.datametricks.com/website-image/Mob-devimg7.png";
const mobdevimg8 = "https://www.datametricks.com/website-image/Mob-devimg8.png";
    return(

        <section>
                <div>

                     {/* 1st Card  */}

              <section>
                  
                  <div className=" mt-lg-4 ">
                    <div className="container  ">
                        <div className="row card123 mx-lg-5 py-lg-4 mt-10 pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12 textcenter">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Strategy & Consulting</h1>

                            <p className='cutting-subtitle mx-lg-5 my-4 font-18'> Our expert team offers strategic consulting to help you define the scope, objectives, and roadmap for your mobile app project. We analyze your business needs and create a tailored strategy that ensures your app aligns with your goals and delivers maximum value. </p>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12">
                            <img src={mobdevimg1} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                 {/* 2nd Card  */}

             <section>
                
                <div>
                  <div className="container  ">
                      <div className="row card123 mx-lg-5 py-lg-4  pos-relative">
                      <div className="overlay1"></div>
                          <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12 textcenter">
                            <h1 className="pt-lg-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >UI/UX Design</h1>
                          <p className='cutting-subtitle mx-lg-5 my-4 '> We prioritize user experience with our innovative UI/UX design services. Our designers create intuitive and engaging interfaces that offer seamless navigation and an exceptional user experience. We focus on aesthetics and functionality to ensure your app stands out and keeps users satisfied. </p>
                          </div>
                          <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12">
                          <img src={mobdevimg2} alt="" className="img-fluid "  />

                          </div>
                      </div>
                    </div>
                </div>
      
           </section>

            {/* 3rd Card  */}

           <section>
                  
                  <div >
                    <div className="container  ">
                        <div className="row card123 mx-lg-5 py-lg-4 pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12 textcenter">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Native App Development (Android/iOS)</h1>

                            <p className='cutting-subtitle mx-lg-5 my-4 font-18 '> Specializing in both Android and iOS platforms, our native app development services ensure high performance and reliability. We develop apps that are optimized for each platform, utilizing the latest technologies and best practices to deliver a smooth and responsive user experience.  </p>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12">
                            <img src={mobdevimg3} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                 {/* 4th Card  */}

             <section>
                
                <div >
                  <div className="container  ">
                      <div className="row card123 mx-lg-5 py-lg-4 pos-relative">
                      <div className="overlay1"></div>
                          <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12 textcenter">
                            <h1 className="pt-lg-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >Cross-Platform App Development</h1>
                          <p className='cutting-subtitle mx-lg-5 my-4 font-18'> Our cross-platform app development services enable you to reach a wider audience by creating apps that run seamlessly on multiple platforms. We use advanced frameworks to develop apps that maintain consistent functionality and performance across different devices and operating systems.  </p>
                          </div>
                          <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12">
                          <img src={mobdevimg4} alt="" className="img-fluid "  />

                          </div>
                      </div>
                    </div>
                </div>
      
           </section>


                {/* 5th Card  */}

                <section>
                  
                  <div >
                    <div className="container  ">
                        <div className="row card123 mx-lg-5 py-lg-4 pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12 textcenter">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >App Development for Wearables</h1>

                            <p className='cutting-subtitle mx-lg-5 my-4 font-18 '> Expand your digital presence with our app development services for wearables. We create innovative apps for devices such as smartwatches and fitness trackers, providing users with convenient and accessible solutions that enhance their daily lives and integrate seamlessly with their wearable technology. </p>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12">
                            <img src={mobdevimg5} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                 {/* 6th Card  */}

             <section>
                
                <div >
                  <div className="container  ">
                      <div className="row card123 mx-lg-5 py-lg-4 pos-relative">
                      <div className="overlay1"></div>
                          <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12 textcenter">
                            <h1 className="pt-lg-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >App Integration & APIs</h1>
                          <p className='cutting-subtitle mx-lg-5 my-4 font-18 '> Our app integration and API services ensure seamless connectivity and functionality within your mobile app ecosystem. We specialize in integrating third-party services and developing robust APIs that enhance your app's capabilities, providing users with a cohesive and enriched experience.  </p>
                          </div>
                          <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12">
                          <img src={mobdevimg6} alt="" className="img-fluid "  />

                          </div>
                      </div>
                    </div>
                </div>
      
           </section>

            {/* 7th Card  */}

           <section>
                  
                  <div >
                    <div className="container  ">
                        <div className="row card123 mx-lg-5 py-lg-4 pos-relative">
                          <div className="overlay"></div>
                            <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12 textcenter">
                            <h1 className="pt-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >QA & Testing</h1>

                            <p className='cutting-subtitle mx-lg-5 my-4 font-18 '> Quality assurance and testing are crucial to delivering a flawless app. Our comprehensive QA and testing services cover all aspects of functionality, performance, and security. We meticulously test your app to identify and resolve any issues, ensuring a smooth and reliable user experience. </p>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12">
                            <img src={mobdevimg7} alt="" className="img-fluid "  />

                            </div>
                        </div>
                      </div>
                  </div>
        
          </section>

                 {/* 8th Card  */}

             <section>
                
                <div >
                  <div className="container  ">
                      <div className="row card123 mx-lg-5 py-lg-4 pos-relative">
                      <div className="overlay1"></div>
                          <div className="col-lg-6 order-lg-1 order-0 col-md-6 col-sm-16 col-12 textcenter">
                            <h1 className="pt-lg-3 px-lg-5 font-23" style={{fontfamily: "Plus Jakarta Sans, sans-serif", fontWeight:"medium", fontSize:25,}} >App Maintenance & Support</h1>
                          <p className='cutting-subtitle mx-lg-5 my-4 font-18 '> Keeping your app updated and running smoothly is essential for sustained success. Our maintenance and support services provide regular updates, bug fixes, and performance optimizations to ensure your app remains secure, efficient, and aligned with the latest technological advancements.  </p>
                          </div>
                          <div className="col-lg-6 order-lg-0 order-1 col-md-6 col-sm-6 col-12">
                          <img src={mobdevimg8} alt="" className="img-fluid "  />

                          </div>
                      </div>
                    </div>
                </div>
      
           </section>



                </div>



        </section>




    )
}
export default MobdevCard;