const Bigcontact = ()=>{
    return(
        <div className="pos-relative">
             <div className='formdiv p-5'>
                                </div>
                                <div className='p-3 pos-relative' style={{zIndex:'99',}}>
                <p className='ff text-center pt-2' style={{ fontSize: '30px', fontWeight: 500, color: 'white' }}>Get In Touch</p>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label className='formlabel' htmlFor="">* Name</label> <br />
                                                <input className='info' type="text" placeholder='Enter name' required /> <br />
                                            </div>
                                            <div className="col-lg-6">

                                                <label className='formlabel' htmlFor="">* Email</label> <br />
                                                <input className='info' type="email" placeholder='Enter name' required /> <br />
                                            </div>

                                        </div>

                                        <label className='formlabel pt-3' htmlFor="">* Mobile Number</label> <br />
                                        <input className='info' type="tel" placeholder="Enter number" required />
                                        <label className='formlabel pt-3' htmlFor="">Message</label> <br />
                                        <textarea className='settextarea' style={{ resize: 'none' }} name="" id="" cols="20" rows="5" placeholder='Type your message'></textarea> <br />
                                        <div className='d-flex gap-2 pt-1'>
                                            <div>
                                                 <input className='setcheckbox' type="checkbox" />
                                            </div>
                                            <div>
                                                  <label className='formlabel' for="vehicle1">I accept the <span><a href="Termscondition" style={{color:'white'}}> Terms & Condition</a></span> </label> <br />
                                            </div>
                                        </div>
                                      
                                        <div className='pt-3'>
                                            <button className='btn-contact mb-0 px-5 mt-0 ' style={{fontWeight:'600', fontSize:'16px'}}>Submit</button>
                                        </div>


                                    </div>
                </div>
        </div>
    )
}
export default Bigcontact
