import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';

const Testimonial = (props)=>{
  const comma = 'https://www.datametricks.com/website-image/comma.png';

    return(
        <div>
 <Swiper
        slidesPerView={1}
        spaceBetween={30}
        keyboard={{
          enabled: true,
        }}
        pagination={{
          type:'fraction'
        }}
        navigation={true}
        modules={[Keyboard, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="container">
          <div className="row">
            <div className="col-lg-5">
<img src={props.image} alt="" />
            </div>
            <div className="col-lg-7">
<div className='pt-5'>
  <img src={comma} alt="" />
</div>
   <p style={{fontSize:'18px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }} className='pt-4'> {props.paragraph}</p>
<p className='fs-19 fw-800'>{props.title}</p>
  {/* Christy Yoland R. */}
<p className='font-family' style={{color:'#A09BAA'}}>{props.subtitle}</p>
  {/* CEO Founder Loker Indonesia */}

            </div>
          </div>
          </div>
       
        </SwiperSlide>
        <SwiperSlide>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
<img src={props.image1} alt="" />
            </div>
            <div className="col-lg-7">
<div className='pt-5'>
  <img src={comma} alt="" />
</div>
<p style={{fontSize:'18px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }} className='pt-4'>{props.paragraph1} </p>
<p className='fs-19 fw-800'>{props.title1}</p>
<p className='font-family' style={{color:'#A09BAA'}}>{props.subtitle1}</p>

            </div>
          </div>
          </div>
         </SwiperSlide>
        <SwiperSlide>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
<img src={props.image2} alt="" />
            </div>
            <div className="col-lg-7">
<div className='pt-5'>
  <img src={comma} alt="" />
</div>
<p style={{fontSize:'18px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }} className='pt-4'>{props.paragraph2} </p>
<p className='fs-19 fw-800'>{props.title2}</p>
<p className='font-family' style={{color:'#A09BAA'}}>{props.subtitle2}</p>

            </div>
          </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
<img src={props.image3} alt="" />
            </div>
            <div className="col-lg-7">
<div className='pt-5'>
  <img src={comma} alt="" />
</div>
<p style={{fontSize:'18px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }} className='pt-4'> {props.paragraph3} </p>
<p className='fs-19 fw-800'>{props.title3}</p>
<p className='font-family' style={{color:'#A09BAA'}}>{props.subtitle3}</p>

            </div>
          </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>      <div className="container">
          <div className="row">
            <div className="col-lg-5">
<img src={props.image4} alt="" />
            </div>
            <div className="col-lg-7">
<div className='pt-5'>
  <img src={comma} alt="" />
</div>
<p style={{fontSize:'18px', fontWeight:'500', fontFamily:"Plus Jakarta Sans, sans-serif", }} className='pt-4'>{props.paragraph4} </p>
<p className='fs-19 fw-800'>{props.title4}</p>
<p className='font-family' style={{color:'#A09BAA'}}>{props.subtitle4}</p>

            </div>
          </div>
          </div></SwiperSlide>
        
      </Swiper>
        </div>
    )
}
export default Testimonial;