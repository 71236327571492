import Chatbox from "../Components/Chatbox";
import Header from "../Components/Header";
import Footer from "../Components/footer";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


// ================ Mailer code start from here =====================

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false); // State for loader
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    try {
      const response = await fetch(
        "https://mailer.datametricks.com/send-email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json(); // Parse JSON from the response

      setResponseMessage(result.message);

      if (result.status === "success") {
        setTimeout(() => {
          setLoading(false); // Hide loader
          navigate("/Successemailpage"); // Redirect to Thank You page
        }, 1000); // Delay redirect by 2 seconds
      } else {
        setLoading(false); // Hide loader if not successful
      }
    } catch (error) {
      setResponseMessage("Error submitting form.");
      setLoading(false); // Hide loader on error
    }
  };

  // ================ Mailer code end here =====================

  const callicon = "https://www.datametricks.com/website-image/callicon-1.svg";
  const emailicon =
    "https://www.datametricks.com/website-image/emailicon-1.svg";
  const locationicon =
    "https://www.datametricks.com/website-image/locationicon.svg";
  const fb = "https://www.datametricks.com/website-image/fbimg.svg";
  const insta = "https://www.datametricks.com/website-image/instaimg.svg";
  const x = "https://www.datametricks.com/website-image/ximg.svg";
  const contactusimg1 =
    "https://www.datametricks.com/website-image/contactus-img1.svg";
  const linkedin = "https://www.datametricks.com/website-image/linkedinimg.svg";

  return (
    <div>
      <section>
        <Header />
      </section>

      <section className="mt0" style={{ marginTop: "6%" }}>
        <div className="contactus-bgimg ">
          <div className="container py-5 pt0">
            <div className="row">
              <div className="col-md-6 text-white p-5 mt-5">
                <h2 className="fw-700 fs-27 plus">We're Here to Help</h2>
                <p className="plus fs-18 pt-3">
                  We're dedicated to providing exceptional support and guidance
                  to help your business thrive in the digital world. Whether you
                  have questions about our services, need technical support, or
                  want to discuss a potential project, we're here to assist you
                  every step of the way.
                </p>
              </div>
              <div className="col-md-6">
                <div
                  className="p-3"
                  style={{
                    border: "1.5px solid #F6F6F6",
                    borderRadius: "12px",
                  }}
                >
                  <div
                    className=" p-4"
                    style={{ backgroundColor: "white", borderRadius: "10px" }}
                  >
                    {/* =========================CONTACT FORM START FROM HERE ================================================================================================= */}
                    <form onSubmit={handleSubmit}>
                      <div className="container">
                        <p className="fw-600 fs-18 plus">Contact Us</p>

                        <div className="row">
                          <div className="col-12 col-lg-6 ">
                            <input
                              className="p-2 phone-number1 w-100"
                              type="text"
                              id="fname"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              required
                              placeholder="Your Name *"
                              style={{
                                outline: "none",
                                border: "0.7px solid #E3E3E3",
                                borderRadius: "6px",
                              }}
                            />
                          </div>
                          <div className="col-12 col-lg-6 ">
                            <input
                              className="p-2 phone-number1 w-100"
                              type="email"
                              id="email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                              name="email"
                              placeholder="Your Email *"
                              style={{
                                outline: "none",
                                border: "0.7px solid #E3E3E3",
                                borderRadius: "6px",
                              }}
                            />
                          </div>
                        </div>

                        <div className="phone-input-container mt-3 d-flex align-items-center">
                          <div className="phone-code me-2">91+</div>
                          <input
                            type="text"
                            className="phone-number"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            placeholder="Mobile Number *"
                          />
                        </div>
                        <textarea
                          className="form-control phone-number1 ps-2 mt-3 fs-12 fw-500 plus"
                          rows="4"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          form="usrform"
                          placeholder="Your Message"
                          required
                          style={{
                            outline: "none",
                            color: "#8C959F",
                            boxShadow: "none",
                          }}
                        ></textarea>
                        <div className="mt-2 d-flex align-items-center gap-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            style={{
                              boxShadow: "none",
                              border: "1px solid #8C959F",
                            }}
                          />
                          <p
                            className="fs-12 plus mb-0 pt-1"
                            style={{ color: "#8C959F" }}
                          >
                            I agree to the{" "}
                            <span className="fs-12 plus mb-0 pt-1">
                              <a
                                href="Termscondition"
                                style={{ color: "blue" }}
                              >
                                {" "}
                                Terms & Condition
                              </a>{" "}
                            </span>
                          </p>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-sm text-white w-100 mt-3 py-2 d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "#00539A",
                            fontSize: "15px",
                            fontWeight: "600",
                            outline: "none",
                            border: "none",
                          }}
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              <div className="spinner"></div> {/* Spinner */}
                              <span className="">Sending...</span>
                            </>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                    {/* ======================== CONTACT FORM END ======================================== */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-4">
        <div>
          <p className="fw-700 fs-36 plus text-center">How to Reach Us</p>
        </div>
        <div className="container mt-3" style={{ backgroundColor: "#F8FCFF" }}>
          <div className="row p-4">
            <div className="col-12 col-md-4 mb-4 mb-lg-0">
              <div
                className="contact1 text-md-left"
                style={{ paddingLeft: "15%" }}
              >
                <img src={emailicon} alt="" />
                <p className="fw-700 fs-16 plus mb-0 pt-3">Email</p>
                <p className="fs-15 plus pt-1" style={{ color: "#8C959F" }}>
                  For general inquiries, please email us at
                  contact@datametricks.com We'll get back to you as soon as
                  possible.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4 mb-lg-0">
              <div
                className="contact1 text-md-left"
                style={{ paddingLeft: "20%" }}
              >
                <img src={callicon} alt="" />
                <p className="fw-700 fs-16 plus mb-0 pt-3">Phone</p>
                <p className="fs-15 plus pt-1" style={{ color: "#8C959F" }}>
                  Give us a call at +91 7303946657. Our team is available to
                  take your calls during business hours.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div
                className="contact1 text-md-left"
                style={{ paddingLeft: "20%" }}
              >
                <img src={locationicon} alt="" />
                <p className="fw-700 fs-16 plus mb-0 pt-3">Visit us</p>
                <p className="pt-1 fs-15 plus" style={{ color: "#8C959F" }}>
                  Datametricks Consulting Private Limited. <br />
                  A40, ITHUM TOWER, Industrial Area, Sector 62, Noida, Uttar
                  Pradesh 201309
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <p className="fw-700 fs-36 plus text-center">
            Follow Us on Social Media
          </p>
          <p className="fs-18 plus text-center" style={{ color: "#79869F" }}>
            Stay connected with Datametricks and keep up to date with the latest
            industry trends, insights, and company updates by following us on
            our social media channels.
          </p>
        </div>
        <div className="container-fluid">
          <div
            className="row justify-content-center py-4 mt-5"
            style={{ border: "1px solid #E0E0E0" }}
          >
            <div className="col-3 col-md-2 text-center">
              <a
                href="https://in.linkedin.com/company/datametricks-consulting-pvt-ltd"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <img src={linkedin} alt="LinkedIn" className="img-fluid" />
              </a>
            </div>
            <div className="col-3 col-md-2 text-center">
              <a
                href="https://www.facebook.com/people/DataMetricks-Consulting-Pvt-Ltd/61554350043470/"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <img src={fb} alt="Facebook" className="img-fluid" />
              </a>
            </div>
            <div className="col-3 col-md-2 text-center">
              <a
                href="https://x.com/datametricks?t=tTJi9XotG-3d1NMQpxXhTQ&s=09"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <img src={x} alt="Twitter" className="img-fluid" />
              </a>
            </div>
            <div className="col-3 col-md-2 text-center">
              <a
                href="https://www.instagram.com/datametricks_pvt_ltd/"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <img src={insta} alt="Instagram" className="img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5 pb-5">
          <p className="fw-700 fs-36 plus text-center">Our Commitment</p>
          <div className="row mt-5">
            <div className="col-12 col-lg-6 mb-4 mb-lg-0">
              <img src={contactusimg1} alt="" className="img-fluid" />
            </div>
            <div className="col-12 col-lg-6">
              <p
                className="paracon fs-18 plus"
                style={{ color: "#79869F", marginTop: "14%" }}
              >
                Your satisfaction is our priority. We are committed to providing
                you with the best possible service and ensuring that all your
                digital needs are met. We look forward to hearing from you and
                working together to achieve your digital goals.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
  <div>
    <Chatbox/>
  </div>
</section>

      <section className="mt-5 paracon">
        <Footer />
      </section>
    </div>
  );
};
export default Contactus;
