import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination,Autoplay} from 'swiper/modules';
import Button from 'react-bootstrap/Button';
// import { FaArrowRight } from 'react-icons/fa';

import 'swiper/css';
import 'swiper/css/pagination';
const Scroller = () => {
  return (
        <div>
            
                   {/* Slide background section  */}
        <section>
        <div style={{position:'relative'}}>
      <Swiper
        direction={'vertical'}
        pagination={{
        
          clickable: true,
        }}
        loop={true}
        speed={1500}
      
        autoplay={{
          delay: 2000,
          stopOnLastSlide:false,

          waitForTransition:2000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper" style={{position:'relative'}} >
        <SwiperSlide>
            <div className="slide-1">
           
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slide-2">
           
            </div>
            </SwiperSlide>
        <SwiperSlide>
            <div className="slide-3">

            </div></SwiperSlide>
        <SwiperSlide>
            <div className="slide-4">
                
            </div></SwiperSlide>

      </Swiper>
      <div className="container pt-3 pt1" style={{position:'absolute',top:'0%',left:'0%',right:'0%',bottom:'0%',zIndex:999}}>
            <div className="slideOne text-center text-white">
                <p className=" mollio  fs-14 " style={{marginBottom:'0'}}>Create Your Dream Home</p>
                <p className="mollio  fs-14 mb-0 "s> Find Inspiration in Every Corner</p>
                <p className="pop  fs-11 pt-3">Lorem ipsum dolor sit amet consectetur. Sed ut gravida ullamcorper magnis venenatis enim laoreet justo egestas. Sit urna elit ac pellentesque urna integer at.</p>
            </div>
            <div className="text-center " >
           
         <a href='/MonovougeProject'>   <Button  className="btn-1 px-5 text-white mt-2" style={{borderRadius:'17px',backgroundColor:'rgba(79, 166, 140, 1)',border:'none'}} >Explore Collections </Button></a>
            </div>
          
      </div>
     
    </div>
      </section>

        </div>
  );
}

export default Scroller;
