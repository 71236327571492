import Header from "../Components/Header";
import Footer from '../Components/footer';

function Successemailpage (){
    const successemail = "https://www.datametricks.com/website-image/successemail.webp";


    
return(
    <div>
        <div>
            <Header/>
        </div>
        <div style={{marginTop:'8%'}}>
        <p className="fs-50 fw-600 text-center" style={{color:'green'}}>Thank you for connect with us</p>
        </div>
        <div className="text-center">
            <img src={successemail} alt="" className="img-fluid" style={{height:'600px'}}/>
        </div>
       




      

     
        <section className="mt-5">
            <Footer/>
        </section>
    </div>
)
}
export default Successemailpage;