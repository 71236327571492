

const Minicontact = ()=>{

const mapPin = 'https://www.datametricks.com/website-image/MapPin.png';
const greymail = 'https://www.datametricks.com/website-image/greymail.png';
const phoneicon = 'https://www.datametricks.com/website-image/phoneicon.png';
const emailiconwebdev = "https://www.datametricks.com/website-image/Envelope.svg";
const phoneiconwebdev = "https://www.datametricks.com/website-image/Phone.svg";
const lociconwebdev = "https://www.datametricks.com/website-image/MapPin.svg";

    return(
        <div>
          
    
        <div className="row justify-content-center">
            <div className="col-lg-6 pb-3">
                                <h1 className='consultant-heading font-23'>Get a Free <br /> Consultation Today</h1>
                                <p className='cutting-subtitle pt-2 font-18'>"Let's Build Your Dream Website and Grow <br /> Your  Business with us"</p>
                                <div className='pt-4' style={{ borderBottomStyle: 'dashed', width: '70%', color: '#6A6A6A' }}>

                                </div>
                                <p className='reachout pt-4 ms-'>Reach out to us at</p>
                                <div className='d-flex gap-2'>
                                    <div>
                                        <img style={{ width: '30px' }} src={lociconwebdev} alt="" />
                                    </div>
                                    <div>
                                        <p  className=' mb-0'>A40, ITHUM TOWER, </p>
                                        <p className=''>Industrial Area, Sector 62, Noida, Uttar Pradesh 201309 </p>
                                    </div>
                                </div>

                                <div className='d-flex gap-2'>
                                    <div>
                                        <img style={{ width: '30px' }} src={emailiconwebdev} alt="" />
                                    </div>
                                    <div>
                                        <p className='cutting-subtitle mb-0'>contact@datametricks.com </p>

                                    </div>
                                </div>
                                <div className='d-flex gap-2 pt-3'>
                                    <div>
                                        <img style={{ width: '30px' }} src={phoneiconwebdev} alt="" />
                                    </div>
                                    <div>
                                        <p className='cutting-subtitle mb-0'>+91 7303946657  </p>

                                    </div>
                                </div>
                            </div>
            {/* <div className="col-lg 6">
             <div className='contactusform pb-5'>
                                    <div className="ps-5 pe-5 pt-5">
                                        <p className='reachout'>Contact Us</p>
                                        <div className="row">
                                         
                                            <div className="col-lg-6">
                                                <input type="text" placeholder='Your Name' style={{color:'#8C959F'}} />
                                            </div>
                                            <div className="col-lg-6">
                                                <input type="email" placeholder='Your Email'style={{color:'#8C959F'}} />
                                            </div>
                                        </div>
                                        <div className="pt-3">
                                            <input style={{ width: '100%',color:'#8C959F' }} type="phone" id="" placeholder='Mobile No' />
                                        </div>
                                        <div className='pt-3'>
                                            <textarea className='settext2' name="" id="" cols="15" rows="4" placeholder='Your Message' style={{color:'#8C959F'}}></textarea>
                                            <div className='d-flex gap-2 pt-1'>
                                                <input type="checkbox" />
                                                <label style={{ color: '#8C959F' }} htmlFor="vehicle1">I accept the <a href="/Termscondition">Terms & Condition </a></label> <br />
                                            </div>
                                            <div className='pt-3'>
                                                <button className='submit-button text-center'>Submit</button>
                                            </div>
                                        </div>
                                    

                                    </div>

                                </div>
                                </div> */}



<div className="col-lg-5">
    <div className='contactusform pb-5'>
        <div className="ps-5 pe-5 pt-5">
            <p className='reachout'>Contact Us</p>
            <form action="../Php/process-form.php" method="POST">
                <div className="row">
                    <div className="col-lg-6">
                        <input className='minicontact_name' type="text" name="name" placeholder='* Your Name' style={{color:'#8C959F'}} />
                    </div>
                    <div className="col-lg-6">
                        <input className='minicontact_email' type="email" name="email" placeholder='* Your Email' style={{color:'#8C959F'}} />
                    </div>
                </div>
                <div className="pt-3">
                    <input style={{ width: '100%',color:'#8C959F' }} type="tel" name="phone" placeholder='* Mobile No' />
                </div>
                <div className='pt-3'>
                    <textarea className='settext2' name="message" cols="15" rows="4" placeholder='Your Message' style={{color:'#8C959F'}}></textarea>
                    <div className='d-flex gap-2 pt-2'>
                        <div>
                        <input type="checkbox" name="terms" id="terms" />
                        </div>
                    
                    <div>
                        <label style={{ color: '#8C959F' }} htmlFor="terms">I accept the <a href="/Termscondition">Terms & Condition </a></label> <br />
                    </div>
                    </div>
                    <div className='pt-3'>
                        <button type="submit" className='submit-button text-center'>Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

            </div>
        
          
           
           
             
        </div>
    )
}
export default Minicontact