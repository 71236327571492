import React, { useState, useEffect } from 'react';
import blogimg1 from "../assets/blogimg1.svg";
const Blog1 = () => {

  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Replace with your actual API URL
    fetch('https://www.enavosoft.com/wp-json/wp/v2/posts')
      .then((response) => response.json())
      .then((data) => {
        setBlogs(data.slice(0,3)); // Assuming data is an array of blog posts
      })
      .catch((error) => {
        console.error('Error fetching the blog data:', error);
      });
  }, []);

  return (
    <div>
      <section>
        <div className='container'>
          <p className='text-center fw-600 fs-35 plus font-23'>
            Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span>
          </p>
          <div className='row justify-content-center mt-lg-4'>
            {blogs.map((blog, index) => (
              <div key={index} className='col-lg-3'>
                <div
                  className='p-2 mb-3'
                  style={{
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src={blogimg1} 
                 
                    className='img-fluid'
                  />
                  <p
                    className="fw-700 fs-17 plus pt-2 font-23 textcenter"
                    style={{ color: 'rgba(51, 51, 51, 1)' }}
                  >
                   Top 5 Technology to Boom in IT Sector
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog1;


