import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const img = 'https://www.datametricks.com/website-image/comlogo.svg';

const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);
  
  return (
    <div className="header-container" >
      <Navbar fixed="top" expand="lg" className="custom-navbar">
        <a href="/">
          <img
            className="companylogo"
            src={img}
            alt="Company Logo"
          />
        </a>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='navbarstyle'/>
        <Navbar.Collapse style={{ marginLeft: '40px' }} id="basic-navbar-nav">
          <Nav className="me-auto gap-4 gapstyle">
            <NavDropdown title="Services" id="basic-nav-dropdown" className={` ${activeLink.startsWith('/Services') ? 'active' : ''}`} style={{fontSize:'17px', fontWeight:'400', fontFamily:'Plus Jakarta Sans, sans-serif'}}>
              <NavDropdown.Item href="/Webdev" className={activeLink === '/Webdev' ? 'active' : ''}>Website Development</NavDropdown.Item>
              <NavDropdown.Item href="/Mobiledev" className={activeLink === '/Mobiledev' ? 'active' : ''}>Mobile Development</NavDropdown.Item>
              <NavDropdown.Item href="/Socialmedia" className={activeLink === '/Socialmedia' ? 'active' : ''}>Social Media</NavDropdown.Item>
              <NavDropdown.Item href="/Digitalmarketing" className={activeLink === '/Digitalmarketing' ? 'active' : ''}>Digital Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/Performancemarketing" className={activeLink === '/Performancemarketing' ? 'active' : ''}>Performance Marketing</NavDropdown.Item>
              <NavDropdown.Item href="/Graphicdesigning" className={activeLink === '/Graphicdesigning' ? 'active' : ''}>Graphic Designing</NavDropdown.Item>
              <NavDropdown.Item href="/Seo" className={activeLink === '/Seo' ? 'active' : ''}>SEO</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/ProjectScreen" className={`navlinkstyle  ${activeLink === '/ProjectScreen' ? 'active' : ''}`}>Our Projects</Nav.Link>
            <Nav.Link href="https://blog.datametricks.com/" className={`navlinkstyle  ${activeLink === '/blog' ? 'active' : ''}`}>Blogs</Nav.Link>
            <Nav.Link href="/Aboutus" className={`navlinkstyle  ${activeLink === '/Aboutus' ? 'active' : ''}`}>About Us</Nav.Link>
            <Nav.Link href="/Contactus" className={`navlinkstyle  ${activeLink === '/Contactus' ? 'active' : ''}`}>Contact Us</Nav.Link>
          </Nav>
          <Nav className="ms-auto"> 
            <Nav.Link href="/Contactus" className="align-items-end">
              <button type="button" className="btn btn-sm text-white plus fs-18" style={{boxShadow:'none', border:'none', paddingLeft:'25px', paddingRight:'25px', paddingTop:'10px', paddingBottom:'10px', borderRadius:'7px', backgroundColor:'rgba(5, 5, 5, 1)',fontWeight:'600'}}>
                Let’s Talk
              </button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;






// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom'; 
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// const img = 'https://www.datametricks.com/website-image/comlogo.svg';


// const Header = () => {

//   const location = useLocation();
//   const [activeLink, setActiveLink] = useState(location.pathname);

//   useEffect(() => {
//     setActiveLink(location.pathname);
//   }, [location.pathname]);
  
//   return (
//     <div className="header-container">
//       <Navbar fixed="top" expand="lg" className="bg-body-tertiary custom-navbar">
//         <a href="/">
//           <img
//             className="companylogo"
//             src={img}
//             alt="Company Logo"
//           />
//         </a>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" className='navbarstyle'/>
//         <Navbar.Collapse style={{ marginLeft: '40px' }} id="basic-navbar-nav">
//           <Nav className="me-auto gap-4 gapstyle  " >
//             <NavDropdown title="Services" id="basic-nav-dropdown" className={`mt-1  ${activeLink.startsWith('/Services') ? 'active' : ''}`} style={{fontSize:'17px',fontWeight:'400',fontFamily:'Plus Jakarta Sans, sans-serif'}}>
//               <NavDropdown.Item href="/Webdev" className={activeLink === '/Webdev' ? 'active' : ''}>Website Developement</NavDropdown.Item>
//               <NavDropdown.Item href="/Mobiledev" className={activeLink === '/Mobiledev' ? 'active' : ''}>Mobile Developement</NavDropdown.Item>
//               <NavDropdown.Item href="/Socialmedia" className={activeLink === '/Socialmedia' ? 'active' : ''}>Social Media</NavDropdown.Item>
//               <NavDropdown.Item href="/Digitalmarketing" className={activeLink === '/Digitalmarketing' ? 'active' : ''}>Digital Marketing</NavDropdown.Item>
//               <NavDropdown.Item href="/Performancemarketing" className={activeLink === '/Performancemarketing' ? 'active' : ''}>Performance Marketing</NavDropdown.Item>
//               <NavDropdown.Item href="/Graphicdesigning" className={activeLink === '/Graphicdesigning' ? 'active' : ''}>Graphic Designing</NavDropdown.Item>
//               <NavDropdown.Item href="/Seo" className={activeLink === '/Seo' ? 'active' : ''}>SEO</NavDropdown.Item>
//             </NavDropdown>
//             <Nav.Link href="/ProjectScreen" className={`navlinkstyle mt-1 ${activeLink === '/ProjectScreen' ? 'active' : ''}`}>Our Projects</Nav.Link>
//             <Nav.Link href="https://blog.datametricks.com/" className={`navlinkstyle mt-1 ${activeLink === '/blog' ? 'active' : ''}`}>Blogs</Nav.Link>
//             <Nav.Link href="/Aboutus" className={`navlinkstyle mt-1 ${activeLink === '/Aboutus' ? 'active' : ''}`}>About us</Nav.Link>
//             <Nav.Link href="/Contactus" className={`navlinkstyle mt-1 ${activeLink === '/Contactus' ? 'active' : ''}`} >Contact us</Nav.Link>
//             <Nav.Link href="/Contactus" className='ms-auto' ><button type="button" className="btn  btn-sm text-white  plus fs-18 " style={{boxShadow:'none',border:'none',paddingLeft:'15px',paddingRight:'15px',paddingTop:'7px',paddingBottom:'7px',borderRadius:'50px',backgroundColor:'rgba(5, 5, 5, 1)'}}>Let’s Talk</button>
//             </Nav.Link>

//           </Nav>
//         </Navbar.Collapse>
//       </Navbar>
//     </div>
//   );
// };

// export default Header;
