
const Form = ()=>{


    return(

        <div>

<div className='container p-4 mt-10' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'10px',marginTop:'120px'}}>
              <p className='fs-22 fw-400 plus mb-4'>Get a Free Consultaion</p>
              <div class="custom-input-container ">
                <input type="text" id="customFullName" name="customFullName" required />
                <label for="customFullName">* Full Name </label>
            </div>
            <div class="custom-input-container mt-4 ">
                <input type="tel" id="mobilenumber" name="mobilenumber" required />
                <label for="mobilenumber">* Mobile Number </label>
            </div>
            <div class="custom-input-container mt-4 ">
                <input type="email" id="email" name="email" required />
                <label for="email">* Email</label>
            </div>
            <div class="custom-input-container mt-4 ">
                <input type="text" id="servicename" name="servicename"  />
                <label for="servicename">Tell us your requirement</label>
            </div>
            <div className='mt-5 '>
            <button type="button" class="btn  btn-sm  w-100 submit-button fs-20 fw-600 plus text-white  mb-2 py-2" style={{backgroundColor:'rgba(255, 162, 49, 1)',boxShadow:'none',border:'none',outline:'none'}}>Submit</button>
            </div>
          </div>

        </div>
       
    )
}
export default Form;